import React from 'react'
import Dots from '../Dots';

import Nav from '../Nav'
const Home = ({showDots})=>{
  const id = 'home';
  return(
    <div className="slide visible" id={id}>
    <div className="flex">
      <main id='left-side'>        
      {showDots ? <Dots />: null} 
        <section id="main">
          <article className=''>
          <h2>Welcome</h2>
          </article>
        </section>
      </main>
      </div>
    </div>
  )
}
export default Home