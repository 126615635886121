import React, { Component } from 'react';

/* Randomly generate an integer between two numbers. */
const r = function(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const colorClassArray = [
    'pink',
    'mint',
    'lavender',
    'baby-blue',
    'yellow'
];
class Dots extends Component{
    componentDidMount(){
        this.animate();
    }
    animate(){
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
        const translateZMin = -725;
        const translateZMax = 600;
        window.jQuery('.dot').velocity({ 
            translateX: [ 
                function() { return "+=" + r(-screenWidth/2.5, screenWidth/2.5) },
                function() { return r(0, screenWidth) }
            ],
            translateY: [
                function() { return "+=" +  r(-screenWidth/2.5, screenWidth/2.5) },
                function() { return r(0, screenHeight) }
            ],
            translateZ: [
                function() { return "+=" + r(translateZMin, translateZMax) },
                function() { return r(translateZMin, translateZMax) }
            ],
            opacity: [ 
                function() { return Math.random() },
                function() { return Math.random() + 0.1 }
            ],
            //complete: ()=> this.props.doneAnimation()
        }, { duration: 6000 })
    }
    renderDot(color){
        if(!color){
            color = r(0,colorClassArray.length-1);
            color = colorClassArray[color];
        }
        return <div className={"dot "+color} />;
    }
    render(){
        const color = this.props.color;
        const dots = Array.apply(null, Array(100));
        return(
            <div id="dot-container">
                {dots.map(() => this.renderDot(this.props.color))}
            </div>
        )
    }
}
export default Dots;