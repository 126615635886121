import React from 'react'
import Dots, {colorClassArray} from '../Dots';

import Nav from '../Nav'
const Contact = ({showDots})=>{
  const id = 'contact';
  return(
    <div className="slide"  id={id}>
    <div className="flex">
      <main id='left-side'>   
      {showDots ? <Dots color={colorClassArray[2]} />: null}
        <section id="main">
          <article>
            <h2>Contact & Media</h2>
            <p className="text-center">Click below</p>
            <ul className="navs flex contact">
              <li><a href='https://www.linkedin.com/in/marie-hughes'><img src='/icon-linkedin.png' /></a></li>
              <li><a href='https://twitter.com/MarieHughes93'><img src='/icon-twitter.png' /></a></li>
              <li><a href='https://github.com/mariehughes93'><img src='/icon-github.png' /></a></li>
              <li><a href='https://www.youtube.com/channel/UCfLFh6I5-pcmO3VNFrJ2-PA'><img src='/icon-youtube.png' /></a></li>
              <li><a href='mailto://contact@mariehughes.net'><img src='/icon-mail.png' /></a></li>
            </ul>
          </article>
        </section>
      </main>
      </div>
    </div>
  )
}

export default Contact