import React from 'react'
import Dots, {colorClassArray} from '../Dots';

import Nav from '../Nav';

const PortfolioItem = ({image, videoUrl, githubUrl,title}) => {
  return (<div className="portfolio-item">
    <div className="content">
    <img src={image} />
    <h3>{title}</h3>
    </div>
    <div className="links">
      <a href={videoUrl}>Demo</a>
      <a href={githubUrl}>Source</a>
    </div>
  </div>);
}
const Portfolio = ({showDots})=>{
  const id = 'portfolio';
  const items = [
    {
      image: '/images/dopa menu.jpg',
      title: 'Dopa Menu',
      videoUrl: 'https://www.youtube.com/watch?v=ks2sODE1PlU',
      githubUrl: 'https://github.com/MarieHughes93/dopa-menu'
    },
    {
      image: '/images/mood-n-progess.jpg',
      title: 'Mood-n-progress',
      videoUrl: 'https://www.youtube.com/watch?v=YXPZ0cUSfb4',
      githubUrl: 'https://github.com/MarieHughes93/mood-n-progress'
    },
    {
      image: '/images/lets meet.jpg',
      title: "Let's Meet Up",
      videoUrl: 'https://www.youtube.com/watch?v=MkcoX3TBdwM&t=9s',
      githubUrl: 'https://github.com/MarieHughes93/Lets-Meet-Up'
    },
    {
      image: '/images/dnd.jpg',
      title: 'DnD',
      videoUrl: 'https://www.youtube.com/watch?v=1hp26136_wI&feature=youtu.be',
      githubUrl: 'https://github.com/MarieHughes93/dnd'
    },
    {
      image: '/images/p5c.jpg',
      title: 'P5C',
      videoUrl: 'https://www.youtube.com/watch?v=O-dhp7SJlTM',
      githubUrl: 'https://github.com/MarieHughes93/p5c'
    },
  ]
  return(
    <div className="slide" id={id}>
      <div className="flex">
      <main id='left-side'>    
        {showDots ? <Dots color={colorClassArray[3]} />: null}
        <section id="main">
          <article>
          <h2>Portfolio</h2>
          <div className="portfolio-items">
          {items.map(item => <PortfolioItem {...item} />)}
          </div>
          </article>
        </section>
      </main>
      </div>
    </div>
  )
}

export default Portfolio

