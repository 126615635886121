import React from 'react'
import Dots, {colorClassArray} from '../Dots';

import Nav from '../Nav'
const About = ({showDots})=>{
  const id = 'about';
  return(
    <div className="slide"  id={id}>
    <div className="flex">
      <main id='left-side'>
      {showDots ? <Dots color={colorClassArray[0]} />: null}
        <section id="main">
          <article>
          <h2>About</h2>
          <p>So you are reading the about me section? That means you must not know me…</p>
          <p>It is a pleasure to meet you! My name is Marie and I am a full stack software developer residing in good ole’ New York. I am a southerner that has relocated to the big apple!</p>

          <p>I am relatively new to coding or as some may say ‘wet behind the ears.’ However, do not let that fool you! I am making waves of my own. I am thirsty for knowledge and eager to learn. Currently I am proficient in Ruby, JavaScript, SQL, HTML, & CSS.  It’s best to keep an eye out for me because I will be making a name for myself… just you wait!</p>
            
          <p>Besides being a ‘coder’ I am also a dork and most importantly… a mother. In my free time I play games on twitch (horribly) or I am anxiously waiting for the next big anime/comic convention.</p>
            
          <p>I am an open book with a million pages waiting to be read. My contact page has all the info you could want or need to get in contact with me! </p>
          </article>
        </section>
      </main>
      </div>
    </div>
  )
}

export default About