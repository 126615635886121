import React, {Component} from 'react'

var delta = 0,
scrollThreshold = 5,
actual = 1;
const slides = [
  "home",
  "about",
  "blog",
  "contact", 
  "portfolio"
];
class Nav extends Component {
  constructor(props){
    super(props);
    this.keyPress = this.keyPress.bind(this);
    this.scrollEffect = this.scrollEffect.bind(this);
  }
  componentDidMount(){
    window.jQuery(window).on('DOMMouseScroll mousewheel', this.scrollHijacking.bind(this));
        
    window.jQuery(document).on('keydown', this.keyPress.bind(this));
  }
  keyPress(event){
    const activeSlideIndex = slides.indexOf(this.props.activeSlide);
      if( event.which=='40' ) {
          this.props.onSlideTransition(slides[Math.min(slides.length -1, activeSlideIndex+1)]);
      } else if( event.which=='38' ) {
          this.props.onSlideTransition(slides[Math.max(0, activeSlideIndex-1)]);
      }
  }
  scrollHijacking(event) {
    const activeSlideIndex = slides.indexOf(this.props.activeSlide);
    // on mouse scroll - check if animate section
    if (event.originalEvent.detail < 0 || event.originalEvent.wheelDelta > 0) { 
        delta--;
        if( Math.abs(delta) >= scrollThreshold){ 
          this.props.onSlideTransition(slides[Math.max(0, activeSlideIndex-1)]);
        delta = 0;
        }
    } else {
        delta++;
        if(delta >= scrollThreshold) { 
          this.props.onSlideTransition(slides[Math.min(slides.length -1, activeSlideIndex+1)]);
        delta =0;
        }
    }
    return false;
  }
  scrollEffect(event, id) {
    event.preventDefault();
    window.magicScrollTo(id);
    window.history.pushState('','',id);
    this.props.onSlideTransition(id.substr(1));
  }
  render(){
    
    
    return(
      <nav className="navbar navs">
        <ul id="navbar-content">
          <li><a href="/#home" onClick={(e) => {this.scrollEffect(e,'#home')}}>Home</a></li>
          <li><a href="/#about" onClick={(e) => {this.scrollEffect(e,'#about')}}>About</a></li>
          <li><a href="/#blog" onClick={(e) => {this.scrollEffect(e,'#blog')}}>Blog</a></li>
          <li><a href="/#contact" onClick={(e) => {this.scrollEffect(e,'#contact')}}>Contact & Media</a></li>
          <li><a href="/#portfolio" onClick={(e) => {this.scrollEffect(e,'#portfolio')}}>Portfolio</a></li>
        </ul>
      </nav>
    )
  }
}

export default Nav