import React from 'react'
import Dots, {colorClassArray} from '../Dots';

import Nav from '../Nav'
const Blog = ({showDots})=>{
  const id = 'blog';
  return(
    <div className="slide"  id={id}>
    <div  className="flex">
      <main id='left-side'>  
      {showDots ? <Dots color={colorClassArray[1]} />: null}
        <section id="main">
          <article>
          <h2>Blog</h2>
          <p>I am still new to blogging so please bear with me. I am finding my voice and it might be changing still. </p>
          <p>It is still rather new to me and I am always welcoming of any criticism. You can find my blog <a href="/http://mariehughes93.github.io/">here</a></p>
          </article>
        </section>
      </main>
      </div>
    </div>
  )
}

export default Blog