import './index.css'
import './boxes.css'
import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import {pages} from './pages/_index'
import Nav from './Nav'
import Dots from './Dots';
 
class App extends Component {
  constructor() {
    super();
    const initialActive = window.location.hash ? window.location.hash.substr(1) : 'home';
    this.state = {
      dots: true,
      activeSlide: initialActive,
    }
  }

  doneAnimating(){
    this.setState({dots: false});
  }
  setActiveSlide(id){
    this.setState({activeSlide: id});
  }
  componentDidMount(){
    window.magicScroll();
    const initialActive = window.location.hash ? window.location.hash.substr(1) : 'home';
    if(initialActive != 'home'){
      window.setTimeout(() => window.magicScrollTo('#'+initialActive), 1000);
    }
  }
  
  
  render () {
    return (
      <div className="">
      <div className="flex"> 
          <pages.Home showDots={this.state.activeSlide == 'home'}/>
          <pages.About showDots={this.state.activeSlide == 'about'}/>
          <pages.Blog showDots={this.state.activeSlide == 'blog'}/>
          <pages.Contact showDots={this.state.activeSlide == 'contact'}/>
          <pages.Portfolio showDots={this.state.activeSlide == 'portfolio'}/>
          <Nav onSlideTransition={this.setActiveSlide.bind(this)} activeSlide={this.state.activeSlide}/>
      </div>
      </div>
    )
  } 
}

ReactDOM.render(
<App />, 
document.getElementById('root'))

