import About from './About'
import Blog from './Blog'
import Contact from './Contact'
import Home from './Home'
import Portfolio from './Portfolio'

export const pages = {
    About,
    Blog,
    Contact,
    Home,
    Portfolio
}